import * as React from "react"
import { navigate } from "gatsby"

import { Theme } from "./ThemeToggler"

import "./styles/content-metadata.scss"

const TagColors = [
  "#1c0221",
  "#7f7caf",
  "#1e441e",
  "#ffb17a",
  "#519e8a",
  "#f87666",
  "#0d0221",
  "#c9dcb3",
  "#253d5b",
]

function colorHexForTag(tag: string): string {
  const firstLetterCodeNormalized = "z".charCodeAt(0) - tag.toLowerCase().charCodeAt(0)
  return TagColors[firstLetterCodeNormalized % TagColors.length]!
}

interface ContentMetadataProps {
  tags: string[]
  theme: Theme
  date?: Date
}

const ContentMetadata: React.FC<ContentMetadataProps> = ({ tags, theme, date }) => (
  <div className={`content-metadata--${theme}`}>
    {
      tags.sort().map((tag) => (
        <p
          className={`tag fs11`}
          key={tag}
          onClick={() => navigate(`/tag/${tag.toLowerCase()}/`)}
          style={
            {
              backgroundColor: colorHexForTag(tag),
              color: "white"
            }
          }
        >
          {tag.toLowerCase()}
        </p>
      ))
    }

    {date !== undefined &&
      <p className={`date fs11`}>({date.toDateString()})</p>
    }
  </div>
)

export default ContentMetadata