import * as React from "react"
import { Link } from "gatsby"

import { ContentCategory, ContentType, IContentNode } from "../types/Content"

import ContentMetadata from "./ContentMetadata"
import HeadingSeparator from "./HeadingSeparator"
import { Theme } from "./ThemeToggler"


import "./styles/recent-stuff.scss"

interface IRecentContent {
  category: ContentCategory;
  date: Date;
  id: string;
  slug: string;
  summary?: string;
  tags: string[];
  title: string;
  type: ContentType;
}

export function makeRecentContentItem(content: IContentNode): IRecentContent {
  return {
    "category": content.frontmatter.category,
    "date": new Date(Date.parse(content.frontmatter.date)),
    "id": content.id,
    "slug": content.fields.slug,
    "summary": content.frontmatter.summary,
    "tags": content.frontmatter.tags,
    "title": content.frontmatter.title,
    "type": content.frontmatter.type,
  }
}

interface RecentStuffProps {
  theme: Theme
  recents: IRecentContent[]
}

const RecentStuff: React.FC<RecentStuffProps> = ({ theme, recents }) => (
  <div className={`recent-stuff--${theme}`}>
    <h2 className={`recent-stuff__title fs32`}>
      Recent Stuff
    </h2>
    
    <HeadingSeparator theme={theme}/>

    <div className={`recent-stuff__content--${theme}`}>
      {
        recents.map((item) => (
          <RecentStuffItem key={`${item.id}`} item={item} theme={theme} />
        ))
      }
    </div>
  </div>
)

export default RecentStuff

interface RecentStuffItemProps {
  item: IRecentContent
  theme: Theme
}

const RecentStuffItem: React.FC<RecentStuffItemProps> = ({ item, theme}) => (
  <div className={`recent-item--${theme}`}>
    <ContentCategoryComponent category={item.category} iconName="" theme={theme} type={item.type} />

    <Link className={`recent-item__link--${theme}`} to={`${item.slug}`}>
      <h2 className={`recent-item__title--${theme}`}>{item.title}</h2>
    </Link>

    <ContentMetadata date={item.date} tags={item.tags} theme={theme} />

    <p className={`recent-item__description--${theme}`}>{item.summary}</p>
  </div>
)

interface ContentCategoryComponentProps {
  iconName: string
  category: string
  theme: Theme
  type: string
}

const ContentCategoryComponent: React.FC<ContentCategoryComponentProps> = ({ category, theme, type }) => (
  <span className={`recent-item__category--${theme} fs11`}>
    {category.toUpperCase()} [{type.toUpperCase()}]
  </span>
)