import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { IContentNode } from "../types/Content"
import { ILocation } from "../types/Location"
import { ITopic } from "../types/Topic"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecentStuff, { makeRecentContentItem } from "../components/RecentStuff"
import { Theme } from "../components/ThemeToggler"

import "./styles/index.scss";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        topics: ITopic[]
      }
    }

    recentContent: {
      edges: [
        {
          node: {
            childMdx: IContentNode
          }
        }
      ]
    }
  }
  location: ILocation
}

const IndexPage: React.FC<IndexPageProps> = ({ data, location }) => (
  <Layout currentPath={location.pathname}>
    {({ theme }) => (
      <>
        <SEO title="Home"/>
        <div className={`home-slant--${theme}`}></div>
        <div className="home-container">
          <div className="home-hero">
            <div className={`home-hero__image--${theme}`}>
              <StaticImage
                alt="dabby home illustration"
                formats={["auto", "webp", "avif"]}
                layout="constrained"
                loading="eager"
                quality={100}
                src="../images/dabby_home.png"
                placeholder="blurred"
                width={1000}
              />
            </div>

            <div className={`home-hero__text--${theme}`}>
              <h1 className="fs45">Hello World 👋🏿</h1>

              <h2 className="fs32 fw-450">
                I'm Dabby －
              </h2>

              <h2 className="fs28 fw-350">
                Thanks for stopping by! Hope you find something interesting on here today.
              </h2>
            </div>
          </div>

          <div className="home-topics">
            {
              data.site.siteMetadata.topics.map((topic) => (
                <TopicItem key={topic.title} theme={theme} topic={topic} />
              ))
            }
          </div>

          <div className="home-recents">
            <RecentStuff
              recents={data.recentContent.edges.map(({ node }) => makeRecentContentItem(node.childMdx))}
              theme={theme}
            />
          </div>

        </div>
      </>
    )}
  </Layout>
)

export default IndexPage

interface TopicItemProps {
  theme: Theme
  topic: ITopic
}

const TopicItem: React.FC<TopicItemProps> = ({ theme, topic }) => (
  <Link className={`home-topics__item--${theme}`} to={`/topic/${topic.title.toLowerCase()}`}>
    <div className="fs45">{topic.icon}</div>
    <h2 className="topic-item__title">{topic.title}</h2>
    <p className="fs18">{topic.description}</p>
  </Link>
)

export const pageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        topics {
          icon
          title
          description
        }
      }
    }

    recentContent: allFile(
      filter: {sourceInstanceName: {in: ["articles"]}}
      sort: {fields: [childMdx___frontmatter___date], order: DESC}
      limit: 5
    ) {
      edges {
        node {
          childMdx {
            id
            body
            fields {
              slug
            }
            frontmatter {
              category
              date
              summary
              tags
              title
              type
            }
          }
        }
      }
    }
  }
`