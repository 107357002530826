import * as React from "react"
import { Theme } from "./ThemeToggler"

import "./styles/heading-separator.scss"

interface HeadingSeparatorProps {
    theme: Theme
}

const HeadingSeparator: React.FC<HeadingSeparatorProps> = ({ theme }) => (
    <div className={`heading-separator--${theme}`}></div>
)

export default HeadingSeparator